* {
  box-sizing: border-box;
}
body {
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  color: #060606;
  font-weight: 700;
}
h5 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
}
h6 {
  font-size: 14px;
  color: #b1b1b1;
  font-weight: 500;
}
.outroPageGrid,
.testPageGrid {
  position: relative;
  height: 100vh;
}
.outroPageGrid {
  display: grid;
  grid-template-rows: 220px 3fr;
  grid-gap: 0;
  height: 100%;
  background: #fef3ef;
}
.outroContent {
  width: 90%;
  min-height: calc(100vh - 220px);
  background: #fff;
  border-radius: 22px 22px 0 0;
  box-shadow: 0 4px 4px 0 rgb(6 6 6 / 25%);
  padding: 20px 0 40px;
  margin: 0 auto;
}
/* Question content ============== */
.testContent {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: calc(100vh - 146px);
  overflow: auto;
}
.testLeftSide,
.testRightSide {
  display: grid;
  grid-template-rows: 1fr 3fr;
  grid-gap: 20px;
  width: 50%;
  padding: 45px 0 30px;
}
.testLeftSide {
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}
.answerOptionsBox,
.questionBox {
  padding: 25px 50px;
}
.questionBox {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.qestionDescription {
  font-weight: 500;
  font-size: 16px;
  color: #b1b1b1;
  margin-bottom: 10px;
}
.question {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
/* Options */
.optionBox {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.option-checked {
  border-color: #52cb9f !important;
}
.optionBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.option {
  width: 100%;
  max-width: 600px;
  height: auto;
  padding: 12px 16px;
  border: 2px solid rgba(6, 6, 6, 0.1);
  border-radius: 20px;
  font-size: 16px;
}
.optionBox input:checked ~ .option {
  border-color: #52cb9f;
}
.optionBox:hover input ~ .option {
  background-color: #e8f7f2;
}
.inrtoLabel,
.testLabel {
  position: absolute;
  z-index: 3;
  min-width: 120px;
  background: #52cb9f;
  border-radius: 0 0 20px 20px;
  padding: 16px;
  font-size: 14px;
  box-shadow: 0 2px 4px 0 rgb(6 6 6 / 25%);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.inrtoLabel {
  background: #f6aea9;
}
.imgHeader {
  height: 100%;
  display: flex;
  justify-content: center;
}
.imgHeader img {
  display: block;
  max-height: 100%;
}
/* Question navbar ============ */
.testNavBar {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-rows: 32px 1fr;
  grid-gap: 10px;
  background: #f1f1f1;
}
.navContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 0 20px 30px;
}
.progressBarBox {
  position: relative;
  width: 100%;
}
.progressBarBox:after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 26px;
  box-shadow: inset 0px 4px 4px 0 rgb(6 6 6 / 25%);
}
.progressBarBg {
  display: flex;
  justify-content: flex-end;
  height: 6px;
  background: linear-gradient(to right, #52cba9, #52cb9f, #fda099);
  margin-bottom: 5px;
}
.progressBarInverted {
  height: 6px;
  background: #e5e5e5;
}
.progressCounter {
  font-size: 14px;

  text-align: center;
}
.label {
  font-size: 14px;

  color: #b1b1b1;
}
.questionCounter span,
.questionCounter strong {
  font-size: 18px;
}
.questionCounter span {
  color: #b1b1b1;
}
.testName {
  font-size: 22px;
  text-align: center;
}
.videoBox {
  width: 100%;
  padding: 70px 50px 50px;
}
video {
  display: block;
  width: 100%;
  max-width: 1240px;
  max-height: calc(100vh - 265px);
  height: 100%;
  background: rgb(153 153 153 / 20%);
  border-radius: 22px;
  margin: 0 auto;
}
.outroTextBox,
.introTextBox {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 620px;
  margin: 0 auto;
}
.outroTextBox p,
.introTextBox p {
  color: #b1b1b1;
  font-weight: 500;
}
.outroTextBox h2,
.introTextBox h2 {
  font-weight: 700;
  margin-bottom: 50px;
}
.outroTextBox h4,
.introTextBox h4 {
  margin-top: 30px;
}
.rules {
  display: flex;
  justify-content: space-evenly;
  padding: 20px 50px;
}
.rule {
  max-width: 300px;
  padding: 40px;
  border-radius: 22px;
  background: #f1f0f0;
  text-align: center;
}
.rule span {
  font-size: 14px;
  line-height: 24px;
}
.rule img {
  margin-bottom: 30px;
}
/* Timer =============*/
.countdown {
  display: grid;
  margin: 0.5em auto;
  width: 6em;
  height: 6em;
}

.countdown::after {
  grid-column: 1;
  grid-row: 1;
  place-self: center;
  animation: num 20s steps(1) infinite;
  content: '0:' counter(s, decimal-leading-zero);
}

@keyframes num {
  0% {
    counter-reset: s 20;
  }
  3% {
    counter-reset: s 19;
  }
  10% {
    counter-reset: s 18;
  }
  15% {
    counter-reset: s 17;
  }
  20% {
    counter-reset: s 16;
  }
  25% {
    counter-reset: s 15;
  }
  30% {
    counter-reset: s 14;
  }
  35% {
    counter-reset: s 13;
  }
  40% {
    counter-reset: s 12;
  }
  45% {
    counter-reset: s 11;
  }
  50% {
    counter-reset: s 10;
  }
  55% {
    counter-reset: s 9;
  }
  60% {
    counter-reset: s 8;
  }
  65% {
    counter-reset: s 7;
  }
  70% {
    counter-reset: s 6;
  }
  75% {
    counter-reset: s 5;
  }
  80% {
    counter-reset: s 4;
  }
  85% {
    counter-reset: s 3;
  }
  90% {
    counter-reset: s 2;
  }
  95% {
    counter-reset: s 1;
  }
  100% {
    counter-reset: s 0;
  }
}

svg {
  grid-column: 1;
  grid-row: 1;
}

[r] {
  fill: none;
  stroke: #f1f0f0;
}

[r] + [r] {
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke: #52cb9f;
  animation: arc 20s linear infinite;
  animation-name: arc, col;
}

@keyframes arc {
  0% {
    stroke-dashoffset: 0px;
  }
}
@keyframes col {
  0% {
    stroke: #52cb9f;
  }
  50% {
    stroke: #d1c38b;
  }
  85% {
    stroke: #fda099;
  }
  100% {
    stroke: #d2756e;
  }
}
/* Buttons ============= */
.btn {
  font-weight: 700;
  padding: 0;
}
.iconBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  border: 0;
  border-radius: 22px;
  background-color: #100f0d;
}
.btnSecondary {
  border: 2px solid rgb(6 6 6 / 10%);
  background: none;
}
.btnPrimary {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  height: 50px;
  border: 0;
  border-radius: 22px;
  background-color: #52cb9f;
  color: #060606;
  font-size: 16px;
}
.btnContentRight {
  justify-content: flex-end;
}
.btnLarge {
  height: 74px;
  min-height: 74px;
  font-size: 20px;
  padding: 0 30px;
}
.btnMedium {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  min-height: 62px;
  min-width: 62px;
  padding: 0 20px;
  border-radius: 22px;
  font-size: 18px;
}
.btnInactive {
  cursor: not-allowed;
  opacity: 50%;
}
.btn:hover {
  background-color: #5bdfb0;
  color: #060606;
  box-shadow: 0 8px 10px 6px rgba(77, 188, 148, 0.15),
    0 4px 8px -2px rgba(77, 188, 148, 0.45);
}
.btn:active,
.btn:focus {
  background-color: #4dbc94;
  color: #060606;
  border: none;
}
.btn.iconBtn:hover {
  background: #1e1c19;
  box-shadow: 0 8px 10px 6px rgba(0, 0, 0, 0.35),
    0 4px 8px -2px rgba(0, 0, 0, 0.75);
}
.btn.iconBtn:focus {
  background: #000;
}
.btn.iconBtn.btnSecondary:hover {
  border-color: #52cb9f;
  background: transparent;
  box-shadow: 0 8px 10px 6px rgba(0, 0, 0, 0.35),
    0 4px 8px -2px rgba(0, 0, 0, 0.75);
  box-shadow: none;
}
.btn.iconBtn.btnSecondary:focus {
  background: transparent;
  border: 2px solid #4dbc94;
}
.btnSelect {
  position: relative;
  width: auto;
  height: 62px;
  padding: 0 20px !important;
  background: none;
  border: 0;
  outline: 2px solid rgb(6 6 6 / 10%);
}
.select {
  position: absolute;
  z-index: 6;
  bottom: -15px;
  width: calc(100% + 20px);
  background: #fff;
  padding: 20px 0 15px;
  border-radius: 22px;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 25%);
}
.selectOptions {
  padding-bottom: 10px;
}
.selectOptions option {
  border-bottom: 1px solid rgb(6 6 6 / 10%);
  width: 100%;
  padding: 4px 20px;
  font-weight: 700;
  text-align: left;
}
.selectOptions option:hover {
  background-color: #e8f7f2;
}
.optionActive {
  color: #52cb9f;
}
.optionInputBox {
  padding: 0 10px;
}
.optionInputBox input {
  width: 100%;
  height: 62px;
  background: transparent;
  padding: 0 10px;
  outline: 2px solid rgb(6 6 6 / 10%);
  border-radius: 22px;
  border: 0;
}
.optionInputBox input:active {
  outline: 2px solid #52cb9f;
}
.value {
  width: 64px;
}
button:disabled:hover,
[type='button']:disabled:hover,
[type='reset']:disabled:hover,
[type='submit']:disabled:hover,
.btnDisabled:hover,
button:disabled,
[type='button']:disabled,
[type='reset']:disabled,
[type='submit']:disabled,
.btnDisabled {
  cursor: not-allowed;
  opacity: 50%;
  filter: grayscale(1);
}
